import React from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

import Container from '../styles/Container'

import circle from '../images/circle.svg'
import faqArrow from '../images/faq-arrow.svg'
import hand from '../images/hand.png'
import grid from '../images/grid-new.svg'
import gridCommonQuestions1 from '../images/grid-common-questions-1.svg'
import gridCommonQuestions2 from '../images/grid-common-questions-2.svg'
import gridCommonQuestions3 from '../images/grid-common-questions-3.svg'
import { Link } from 'gatsby'

const FAQContainer = styled.div`
  padding-top: 60px;
  background-image: url(${grid});
  background-position: center bottom;
  position: relative;
  @media (min-width: 680px) {
    padding-top: 70px;
  }
  @media (min-width: 1200px) {
    background-image: none;
    padding-top: 96px;
  }
`

const GridBottom = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 301px;
    background-image: url(${gridCommonQuestions1});
    background-position: center bottom;
  }
`

const GridBottom2 = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    left: 0px;
    bottom: 301px;
    width: 100%;
    height: 156px;
    background-image: url(${gridCommonQuestions3});
    background-position: center bottom;
  }
`

const GridBottom3 = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    left: 0px;
    bottom: 457px;
    /* width: 56%; */
    width: 100%;
    height: calc(100% - 457px);
    background-image: url(${gridCommonQuestions2});
    background-position: center bottom;
  }
`

const CustomContainer = styled(Container)`
  position: relative;
`

const ItemContainer = styled.div`
  padding-bottom: 45px;
  max-width: 271px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1px;
  @media (min-width: 400px) {
    max-width: 344px;
  }
  @media (min-width: 480px) {
    max-width: 416px;
  }
  @media (min-width: 560px) {
    max-width: 488px;
  }
  @media (min-width: 680px) {
    max-width: 416px;
  }
  @media (min-width: 810px) {
    max-width: 488px;
  }
  @media (min-width: 1200px) {
    padding-bottom: 80px;
    max-width: 811px;
    left: 7px;
    position: relative;
  }
`

const Circle = styled.img`
  width: 30px;
  position: absolute;
  left: -15px;
  top: 20px;
  @media (min-width: 1200px) {
    width: 40px;
    left: -20px;
    top: 55px;
  }
`

const Content = styled.button`
  width: 100%;
  text-align: left;
  position: relative;
  padding-top: 17px;
  padding-right: 20px;
  padding-bottom: 52px;
  padding-left: 34px;
  background-color: #fdfbf9;
  @media (min-width: 1200px) {
    margin-left: 0px;
    padding-top: 42px;
    padding-right: 56px;
    padding-bottom: 38px;
    padding-left: 74px;
  }
`

const Question = styled.p`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.25px;
  font-weight: 700;
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const Answer = styled.p`
  margin-top: 26px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 100;
  @media (min-width: 1200px) {
    margin-top: 8px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }
`

const FAQArrow = styled.img`
  width: 9px;
  position: absolute;
  right: 16px;
  bottom: 21px;
  ${p => p.expanded === 'auto' && 'transform: rotate(180deg);'}
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  @media (min-width: 1200px) {
    display: none;
  }
`

const Mobile = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  @media (min-width: 1200px) {
    display: none;
  }
`
const Desktop = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
`

const ReturnToTopContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 37px;
  @media (min-width: 680px) {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 325px;
  }
  @media (min-width: 1200px) {
    padding-top: 230px;
    padding-bottom: 244px;
  }
`

const ReturnToTopButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 10px;
  /* padding: 11px 16px 11px 34px; */
  padding: 10px 16px 10px 27px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.11px;
  color: #0859ff;
  background-color: #fdfbf9;
  position: relative;
  left: 60px;
  @media (min-width: 400px) {
    left: 95px;
  }
  @media (min-width: 480px) {
    left: 131px;
  }
  @media (min-width: 560px) {
    left: 167px;
  }
  @media (min-width: 680px) {
    left: 1px;
    margin-right: 0px;
    padding: 8px 33px 9px 33px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.13px;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 1px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 30px;
      }
    }
  }
  @media (min-width: 1200px) {
    background-color: transparent;
    left: -24px;
  }
`

const ReturnToTopImg = styled.img`
  width: 8px;
  transform: rotate(180deg);
  margin-left: 14px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const Hand = styled.img`
  display: none;
  @media (min-width: 680px) {
    display: block;
    width: 323px;
    position: absolute;
    bottom: 0px;
    left: calc(50% - 17px);
    background-color: #fdfbf9;
  }
  @media (min-width: 1200px) {
    width: 397px;
    position: absolute;
    bottom: -19px;
    left: calc(50% + 149px);
    background-color: #fdfbf9;
  }
  @media (min-width: 1300px) {
    width: 433px;
    bottom: -16px;
  }
  @media (min-width: 1400px) {
    width: 430px;
    bottom: -13px;
    left: calc(50% + 224px);
  }
`

const faqs = [
  {
      "question": "What is the application process like?",
      "answer": "If you’re interested in joining our team, you can submit an application directly via the website. You will be asked to submit your basic information, a CV and a brief cover letter. After that, one of our team members will be in touch with you to organise an interview, which will take place directly using our classroom software!"
  },
  {
      "question": "How do I receive a booking / order?",
      "answer": "Our tutors are notified via text when they receive a new booking."
  },
  {
      "question": "Can I manage my own time?",
      "answer": "Absolutely. We believe you should have a chance to shine even if you have busy schedules outside of working with us. As a result we've made it easy for you to regularly update your available time and desired commitment levels, allowing us to plan your timetable better."
  },
  {
      "question": "How easy is it to contact my student and / or consultant?",
      "answer": "Very easy. Messages and files can be sent to your student and/or consultant any time outside of class time via our in-built messaging system. This extra level of support helps you to better fulfil your student’s requirements and obtain the highest levels of achievement for yourself and your student."
  },
  {
      "question": "How do I log into the classroom?",
      "answer": <p>You can enter the classroom directly by logging on to your tutor dashboard and clicking the 'Enter Classroom' button. If you have more than one student, you'll then be directed to an additional landing page where you will be invited to select which classroom you wish to enter.<br/><br/>Before each class, you’ll be notified via text 24 hours and 1 hour in advance of the scheduled start time. <br/><br/>The button will flash immediately before the start of a class and when a class is currently in progress. However, your classroom will remain open and accessible throughout the duration of a booked course of study, so you can enter the classroom anytime outside of lesson time to review notes or upload teaching materials.</p>
  },
  {
      "question": "Can I reschedule classes?",
      "answer": <p>Yes — we operate a 24 hour cancellation/rescheduling policy. More information on the protocol for class rescheduling and cancellation can be found in our <Link to="TNC"><strong>Tutor Terms of Service</strong></Link>.</p>
  },
  {
      "question": "What if a student doesn’t show up or cancels at the last minute?",
      "answer": <p>We still pay half the hourly fee to the tutor if the student cancels at the last minute. In rare cases where the student doesn't show up, tutors are paid half of the hourly fee.<br/><br/>We oblige our tutors to wait 30 minutes before they leave the classroom. Students often arrange online classes for immediately after school, and students can sometimes be held back by their offline commitments, so we appreciate our tutors' patience in such cases.<br/><br/>Tutors and students are both bound by similar standards and penalties relating to last-minute cancellation or no-show, further details of which can be found in our <Link to="TNC"><strong>Tutor Terms of Service</strong></Link>.</p>
  },
  {
      "question": "How does the payment work?",
      "answer": "After the tutor receives their first booking, the tutor will receive an email asking for their bank details. We pay directly to our tutors' bank accounts, and are able to pay out in GBP and USD."
  },
  {
      "question": "How often am I paid?",
      "answer": "We pay directly to our tutors' bank accounts once every two weeks."
  }
];
export default class Example extends React.Component {
  goToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
  }
  render() {
    return (
      <FAQContainer>
        <GridBottom3 />
        <GridBottom2 />
        <GridBottom />
        <CustomContainer>
          {faqs.length > 0 &&
            faqs.map(({ question, answer }, index) => {
              return <FAQItem key={index} question={question} answer={answer} />
            })}
          <ReturnToTopContainer>
            <ReturnToTopButton onClick={this.goToTop}>
              <div>return to top</div>
              <ReturnToTopImg src={faqArrow} alt="Return to Top" />
            </ReturnToTopButton>
          </ReturnToTopContainer>
          <Hand src={hand} alt="" />
        </CustomContainer>
      </FAQContainer>
    )
  }
}

class FAQItem extends React.Component {
  state = {
    height: 0,
  }

  toggle = () => {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { height } = this.state
    const { question, answer } = this.props
    return (
      <ItemContainer>
        <Mobile>
          <Content onClick={this.toggle}>
            <Circle src={circle} alt="" />
            <Question>{question}</Question>
            <AnimateHeight duration={300} easing="cubic-bezier(0.455, 0.03, 0.515, 0.955)" height={height}>
              <Answer>{answer}</Answer>
            </AnimateHeight>
            <FAQArrow src={faqArrow} alt="" expanded={height} />
          </Content>
        </Mobile>
        <Desktop>
          <Content as="div">
            <Circle src={circle} alt="" />
            <Question>{question}</Question>
            <AnimateHeight duration={300} easing="cubic-bezier(0.455, 0.03, 0.515, 0.955)" height={height}>
              <Answer>{answer}</Answer>
            </AnimateHeight>
            <Answer>{answer}</Answer>
            <FAQArrow src={faqArrow} alt="" expanded={height} />
          </Content>
        </Desktop>
      </ItemContainer>
    )
  }
}
