import React from 'react'
import styled from 'styled-components'

import Container from '../styles/Container'

import FAQ from '../components/FAQ'
import SEO from '../components/SEO'

const Wrapper = styled.div`
  padding-top: 132px;
  @media (min-width: 680px) {
    padding-top: 90px;
  }
  @media (min-width: 1200px) {
    padding-top: 160px;
  }
`

const Heading = styled.h1`
  margin-bottom: 120px;
  font-family: Dignitas, sans-serif;
  font-size: 44px;
  line-height: 47px;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: #efe0d7;
  max-width: 240px;
  @media (min-width: 680px) {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 1.67px;
  }
  @media (min-width: 980px) {
    max-width: 600px;
    font-size: 100px;
    line-height: 100px;
    letter-spacing: 1.8px;
  }
  @media (min-width: 1200px) {
    max-width: 600px;
    margin-bottom: 243px;
    font-size: 120px;
    line-height: 130px;
    letter-spacing: 2.5px;
  }
`

const Blue = styled.span`
  color: #0859ff;
`

const CommonQuestions =  () => {
  return (
    <>
      <Container>
        <SEO title={`Common Questions`} description={``} images={``} />
        <Wrapper>
          <Heading>
            A few common <Blue>questions…</Blue>
          </Heading>
        </Wrapper>
      </Container>
      <FAQ />
    </>
  )
}

export default CommonQuestions
